<template>
  <fp-table-list
    v-if="modulesFileds[baseName]"
    :ref="'fp-client-module-diary-' + baseName"
    refId="fp-client-module-diary-list"
    :hasPagination="true"
    :items="items"
    :fields="modulesFileds[baseName]"
    :limitedDataLoad="true"
    @nextStackLoad="getNextStackLoad"
    sortBy="MeasuredAt"
    :sortDesc="true"
    :selectable="true"
    :noSelectOnClick="true"
  >
    <!-- Közös elemek -->
    <template #MeasuredAt="row">
      {{
        $convertDateToString(row.item.MeasuredAt, "YYYY-MM-DD HH:mm")
      }}</template
    >
    <template #Input="row">
      <img
        style="border: solid; border-width: 1px; border-radius: 10px"
        :src="
          require('@/assets/' + $enums.DataSource[row.item.SourceName].Path)
        "
      />
    </template>
    <template v-if="!PatientId" #Delete="row">
      <img
        @click="deleteDiary(row.item)"
        style="width: 30px"
        src="@/assets/settings-button/muvelet4.png"
      />
    </template>
    <template #SelectDiaryEntry="row">
      <b-button @click="selectEntry(row.item.EntryId)">Kiválaszt</b-button>
    </template>
    <!-- Mozgás -->
    <template #ExerciseType="row">{{
      row.item.ExerciseType ? $getLanguagedText(row.item.ExerciseType.Name) : null
    }}</template>
    <template #Intensity="row">{{
      row.item.ExerciseType ? $getLanguagedText(row.item.ExerciseType.Intensity) : null
    }}</template>
    <template #Distance="row">
      <div v-if="row.item.Distance">{{ row.item.Distance }} m</div>
      <div v-else></div>
    </template>
    <!-- Testtömeg -->
    <template #BmiScore="row">{{
      Intl.NumberFormat({ maximumSignificantDigits: 1 }).format(
        parseFloat(row.item.BmiScore)
      )
    }}</template>
    <template #BodyHeight="row">
      <div v-if="row.item.BodyHeight">{{ row.item.BodyHeight }} m</div>
      <div v-else></div>
    </template>
    <template #BodyWeight="row">
      <div v-if="row.item.BodyWeight">{{ row.item.BodyWeight }} kg</div>
      <div v-else></div>
    </template>
    <!-- Vérnyomás, Pulzus -->
    <template #PulseValue="row">
      <div v-if="row.item.PulseValue">
        <div v-if="row.item.PulseValue">{{ row.item.PulseValue }} bmp</div>
      </div>
      <div v-else></div>
    </template>
    <!-- Véroxigénszint, Pulzus -->
    <template #Circumstances="row">
      {{
        row.item.Circumstances
          ? $t($enums.Circumstances[row.item.Circumstances].Text)
          : null
      }}
    </template>
    <!-- Véroxigén -->
    <template #OxygenSaturationValue="row">
      <div v-if="row.item.OxygenSaturationValue">
        {{ row.item.OxygenSaturationValue }} %
      </div>
      <div v-else></div>
    </template>
    <!-- Táplálkozás -->
    <template #MealName="row">
      <!-- {{ $t("enums.MealName." + row.item.MealName).Text }} -->
      {{ row.item.MealName ? $t(mealNames[row.item.MealName].Text) : null }}
    </template>

    <!-- Gyógyszer Neve -->
    <template #MedicineName="row">
        {{ row.item.MedicineName ? $getLanguagedText(row.item.MedicineName) : null }}
    </template>

    <template #Items="row">
      <div
        style="white-space: nowrap"
        v-for="(item, index) in row.item.Items"
        :key="index"
      >
        {{
          item.Name +
          ", " +
          item.Amount +
          " " +
          $t(units[item.Unit].Text) +
          ", " +
          (item.Calories ? item.Calories : 0) +
          " kcal"
        }}
        </div>
    </template>
    <!-- Vércukorszint -->
    <template #MealQuantity="row">
      {{
        row.item.MealQuantity
          ? $t($enums.MealQuantity[row.item.MealQuantity].Text)
          : null
      }}
    </template>
    <!-- Inzulin, Vércukorszint -->
    <template #MealRelation="row">
      <!-- {{ $t("enums.MealRelation." + row.item.MealRelation).Text }} -->
      {{
        row.item.MealRelation
          ? $t(mealRelations[row.item.MealRelation].Text)
          : null
      }}
    </template>
    <!-- Légzés -->
    <template #AvgInhalationsPerMinute="row">
      {{
        $t("module.diaries.respiration.perMin", {
          m: calculateAverage(
            row.item.MeasurementDuration,
            row.item.TotalInhalationsCount
          ),
        })
      }}
    </template>
    <template #MeasurementDuration="row">
      {{ convertStringToTime(row.item.MeasurementDuration) }}
    </template>
    <!-- <template #MinInhalationsPerMinute="row">
      {{
        $t("module.diaries.respiration.perMin", {
          m: row.item.MinInhalationsPerMinute,
        })
      }}
    </template>
    <template #MaxInhalationsPerMinute="row">
      {{
        $t("module.diaries.respiration.perMin", {
          m: row.item.MaxInhalationsPerMinute,
        })
      }}
    </template> -->
    <!-- <template #RespirationDetails="row">
      <b-button @click="$set(row.item, 'showDetails', true)">{{
        $t("module.diaries.respiration.details")
      }}</b-button>
      <fp-form-modal v-model="row.item.showDetails" size="xl">
        <template #header>
          <p>
            {{
              row.item.MeasurementName +
              " - " +
              $convertDateToString(row.item.MeasuredAt, "YYYY.MM.DD HH:mm:ss")
            }}
          </p>
        </template>
        <template #content>
          <respiration-measurement
            :ChartData="row.item.MeasurementData"
            :SamplingRate="row.item.SamplingRate"
            :DiaryEntryId="row.item.EntryId"
            :UserId="PatientId"
            @delete-close="onDeleteClose(row.item)"
            :FullData="row.item"
          >
          </respiration-measurement>
        </template>
      </fp-form-modal>
    </template> -->
  </fp-table-list>
</template>

<script>
import Vue from "vue";
import { DiaryLogic } from "../../Logic/Backend/diary";
import moment from "moment";
/* import PulmonologyRespirationMeasurement from "@/components/module/chart/PulmonologyRespirationMeasurement.vue";

Vue.component("respiration-measurement", PulmonologyRespirationMeasurement); */

export default {
  name: "ClientModuleDiary",
  data() {
    return {
      items: [],
      modulesFileds: {
        exercise: [
          { key: "Input", label: "" },
          {
            key: "ExerciseType",
            label: this.$t("module.diaries.exercise.exerciseType"),
          },
          {
            key: "Intensity",
            label: this.$t("module.diaries.exercise.intensity"),
          },
          {
            key: "Duration",
            label: this.$t("module.diaries.exercise.duration"),
          },
          {
            key: "CaloriesBurned",
            label: this.$t("module.diaries.exercise.burnedCalories"),
          },
          {
            key: "Distance",
            label: this.$t("module.diaries.exercise.distance"),
          },
          {
            key: "StepCount",
            label: this.$t("module.diaries.exercise.stepCount"),
          },
          {
            key: "FloorCount",
            label: this.$t("module.diaries.exercise.floorCount"),
          },
          {
            key: "MeasuredAt",
            label: this.$t("module.diaries.addTimestamp"),
            sortable: true,
          },
          { key: "Delete", label: "" },
        ],
        blood_pressure: [
          { key: "Input", label: "" },
          {
            key: "Category",
            label: this.$t("module.diaries.bloodPressure.category"),
          },
          {
            key: "SystolicPressure",
            label: this.$t("module.diaries.bloodPressure.systole"),
          },
          {
            key: "DiastolicPressure",
            label: this.$t("module.diaries.bloodPressure.diastole"),
          },
          {
            key: "PulseValue",
            label: this.$t("module.diaries.bloodPressure.pulse"),
          },
          {
            key: "MeasuredAt",
            label: this.$t("module.diaries.addTimestamp"),
            sortable: true,
          },
          this.PatientId ? "" : { key: "Delete", label: "" },
        ],
        body_weight: [
          { key: "Input", label: "" },
          {
            key: "BodyWeight",
            label: this.$t("module.diaries.bodyWeight.weight"),
          },
          {
            key: "BodyHeight",
            label: this.$t("module.diaries.bodyWeight.height"),
          },
          {
            key: "FatPercentage",
            label: this.$t("module.diaries.bodyWeight.fatPercentage"),
          },
          {
            key: "WaterPercentage",
            label: this.$t("module.diaries.bodyWeight.waterPercentage"),
          },
          {
            key: "MusclePercentage",
            label: this.$t("module.diaries.bodyWeight.musclePercentage"),
          },
          {
            key: "BmiScore",
            label: this.$t("module.diaries.bodyWeight.bmiScroe"),
          },
          {
            key: "BmiCategory",
            label: this.$t("module.diaries.bodyWeight.bmiCategory"),
          },
          {
            key: "MeasuredAt",
            label: this.$t("module.diaries.addTimestamp"),
            sortable: true,
          },
          { key: "Delete", label: "" },
        ],
        oxygen_saturation: [
          { key: "Input", label: "" },
          {
            key: "OxygenSaturationValue",
            label: this.$t("module.diaries.oxygenSaturation.value"),
          },
          {
            key: "Circumstances",
            label: this.$t("module.diaries.oxygenSaturation.circumstances"),
          },
          {
            key: "MeasuredAt",
            label: this.$t("module.diaries.addTimestamp"),
            sortable: true,
          },
          { key: "Delete", label: "" },
        ],
        pulse: [
          { key: "Input", label: "" },
          {
            key: "PulseValue",
            label: this.$t("module.diaries.pulse.pulseValue"),
          },
          {
            key: "Circumstances",
            label: this.$t("module.diaries.pulse.circumstances"),
          },
          {
            key: "MeasuredAt",
            label: this.$t("module.diaries.addTimestamp"),
            sortable: true,
          },
          { key: "Delete", label: "" },
        ],
        mood: [
          { key: "Input", label: "" },
          {
            key: "MoodValue",
            label: this.$t("module.diaries.mood.moodValue"),
          },
          {
            key: "EnergyLevel",
            label: this.$t("module.diaries.mood.energyLevel"),
          },
          {
            key: "StressLevel",
            label: this.$t("module.diaries.mood.stressLevel"),
          },
          {
            key: "BalanceLevel",
            label: this.$t("module.diaries.mood.balanceLevel"),
          },
          {
            key: "MeasuredAt",
            label: this.$t("module.diaries.addTimestamp"),
            sortable: true,
          },
          { key: "Delete", label: "" },
        ],
        sleep: [
          { key: "Input", label: "" },
          {
            key: "SleepQuality",
            label: this.$t("module.diaries.sleep.sleepQuality"),
          },
          {
            key: "SleepDuration",
            label: this.$t("module.diaries.sleep.sleepDuration"),
          },
          {
            key: "MeasuredAt",
            label: this.$t("module.diaries.addTimestamp"),
            sortable: true,
          },
          { key: "Delete", label: "" },
        ],
        blood_glucose: [
          { key: "Input", label: "" },
          {
            key: "BloodGlucoseValue",
            label: this.$t("module.diaries.bloodGlucose.bloodGlucoseValue"),
          },
          {
            key: "MealRelation",
            label: this.$t("module.diaries.bloodGlucose.mealRelation"),
          },
          {
            key: "MealQuantity",
            label: this.$t("module.diaries.bloodGlucose.mealQuantity"),
          },
          {
            key: "MeasuredAt",
            label: this.$t("module.diaries.addTimestamp"),
            sortable: true,
          },
          { key: "Delete", label: "" },
        ],
        insulin: [
          { key: "Input", label: "" },
          {
            key: "InsulinValue",
            label: this.$t("module.diaries.insulin.insulinValue"),
          },
          {
            key: "MealRelation",
            label: this.$t("module.diaries.insulin.mealRelation"),
          },
          {
            key: "MeasuredAt",
            label: this.$t("module.diaries.addTimestamp"),
            sortable: true,
          },
          { key: "Delete", label: "" },
        ],
        nutrition: [
          { key: "Input", label: "" },
          {
            key: "MealName",
            label: this.$t("module.diaries.nutrition.mealName"),
          },
          {
            key: "Items",
            label: this.$t("module.diaries.nutrition.items"),
          },
          {
            key: "Remark",
            label: this.$t("module.diaries.nutrition.remark"),
          },
          {
            key: "MeasuredAt",
            label: this.$t("module.diaries.addTimestamp"),
            sortable: true,
          },
          { key: "Delete", label: "" },
        ],
        medicine: [
          { key: "Input", label: "" },
          {
            key: "MedicineName",
            label: this.$t("module.diaries.medicine.medicineName"),
          },
          {
            key: "Quantity",
            label: this.$t("module.diaries.medicine.takenCount"),
          },
          { key: "MeasuredAt", label: this.$t("module.diaries.addTimestamp") },
          { key: "Delete", label: "" },
        ],
        respiration: [
          {
            key: "MeasuredAt",
            label: this.$t("module.diaries.addTimestamp"),
            sortable: true,
          },
          {
            key: "MeasurementName",
            label: this.$t("module.diaries.respiration.name"),
          },
          {
            key: "MeasurementDuration",
            label: this.$t("module.diaries.respiration.duration"),
          },
          {
            key: "MinInhalationsPerMinute",
            label: this.$t("module.diaries.respiration.minimum"),
          },
          {
            key: "MaxInhalationsPerMinute",
            label: this.$t("module.diaries.respiration.maximum"),
          },
          {
            key: "TotalInhalationsCount",
            label: this.$t("module.diaries.respiration.total"),
          },
          /* {
            key: "AvgInhalationsPerMinute",
            label: this.$t("module.diaries.respiration.average"),
          }, */
          /* {
            key: "RespirationDetails",
            label: this.$t("module.diaries.respiration.details"),
          }, */
          {
            key: "SelectDiaryEntry",
            label: "",
          },
        ],
      },
      stackLastDate: null,
      mealRelations: this.$enums.MealRelation,
      mealNames: this.$enums.MealName,
      units: this.$enums.Unit,
      selectredEntryId: null,
    };
  },
  model: {
    prop: "refreshList",
    event: "refreshed",
  },
  props: {
    PatientId: [String, Number],
    refreshList: Boolean,
    baseName: String,
  },
  watch: {
    async refreshList(input) {
      if (input) {
        this.items = [];
        await this.getList();
        this.$emit("refreshed", false);
      }
    },
    async baseName() {
      this.items = [];
      await this.getList();
    },
  },
  methods: {
    selectEntry(entryId) {
      this.$emit("selectEntryId", entryId);

      const oldSelectedEntryId = this.selectredEntryId;
      const oldEntryIndex = this.items.findIndex(
        (i) => i.EntryId == oldSelectedEntryId
      );
      if (oldSelectedEntryId) {
        this.$refs["fp-client-module-diary-" + this.baseName].$refs[
          "fp-client-module-diary-list"
        ].unselectRow(oldEntryIndex);
      }

      this.selectredEntryId = entryId;
      const entryIndex = this.items.findIndex((i) => i.EntryId == entryId);
      this.$refs["fp-client-module-diary-" + this.baseName].$refs[
        "fp-client-module-diary-list"
      ].selectRow(entryIndex);
    },
    async onDeleteClose(item) {
      //CONSIDERm: ne mindig újra lehívni, csak valahogy kitörölni?
      Vue.set(item, "showDetails", false);
      this.items = [];
      await this.getList();
      this.$emit("changeData");
    },
    // átlag légzés számítás
    calculateAverage(length, count) {
      const mins = moment.duration(length).asMinutes();
      return Math.round(count / mins);
    },
    convertStringToTime(timeAsString) {
      // muszáj ez a hackelés - TODO: ..., nem jó ha a duration több mint 24 óra!
      const asDuration = moment.duration(timeAsString);
      const time = moment()
        .hours(asDuration.hours())
        .minutes(asDuration.minutes())
        .seconds(asDuration.seconds());
      return time.format("HH:mm:ss");
    },
    async deleteDiary(item) {
      if (
        confirm(this.$t("requestResponse.basic.deleteElementConfirmMessage")) &&
        !this.PatientId
      ) {
        const result = await DiaryLogic.deleteDiaryEntry(
          this.baseName,
          item.EntryId
        );
        if (!result.Code) {
          this.$bvToast.toast(
            this.$t("requestResponse.moduleDiary.successDeleteElement"),
            {
              title: this.$t("base.basic.delete"),
              variant: "info",
              solid: true,
            }
          );
          this.items = [];
          await this.getList();
        } else {
          this.$bvToast.toast(result.Message, {
            title: this.$t("requestResponse.moduleDiary.errorDeleteElement"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
    getNextStackLoad() {
      const currentLastDate = this.items[this.items.length - 1].MeasuredAt;
      if (moment(currentLastDate).isSame(moment(this.stackLastDate))) {
        this.getList({
          "MeasuredAt-to": moment(this.stackLastDate)
            .subtract(1, "seconds")
            .format(),
        });
      }
    },
    async getList(params) {
      //console.log(params);
      if (this.modulesFileds[this.baseName]) {
        if (this.PatientId) {
          await this.getPatientsList({
            ...params,
            "-orderbydesc": "MeasuredAt",
            "-limit": 101,
          });
        } else {
          await this.getUsersList({
            ...params,
            "-orderbydesc": "MeasuredAt",
            "-limit": 101,
          });
        }
      }
    },
    async getPatientsList(params) {
      const response = await DiaryLogic.getPatientDiary(
        this.baseName,
        this.PatientId,
        params
      );
      // getting the diary data
      if (!response.Code) {
        this.items = this.items.concat(response);
        this.stackLastDate = response.length
          ? response[response.length - 1].MeasuredAt
          : null;
      } else {
        this.$bvToast.toast(response.Message, {
          title: this.$t("requestResponse.moduleDiary.errorGetList"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async getUsersList(params) {
      const response = await DiaryLogic.getDiary(this.baseName, params);
      // getting the diary data
      if (!response.Code) {
        this.items = this.items.concat(response);
        this.stackLastDate = response.length
          ? response[response.length - 1].MeasuredAt
          : null;
      } else {
        this.$bvToast.toast(response.Message, {
          title: this.$t("requestResponse.moduleDiary.errorGetList"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  async mounted() {
    await this.getList();
  },
};
</script>
<style>
.diary-title {
  font-size: 1.5em;
}
.e-grid .e-headercell.customcss {
  font-size: 20px;
  word-wrap: normal;
}
</style>
